import {
  Box
} from '@chakra-ui/react';
import PropTypes from 'prop-types';

import Footer from '../Footer/Footer';
import Header from '../Header/Header';

function LandingHeroLayout({ children, heroComponent }) {
  return <>
    <Header />
    {/* Ensure page is always a minimum of 100vh: header height + 368px (footer) */}
    <Box
      as="main"
      minHeight="calc(100vh - (var(--header-height) + 368px))"
      mt="calc(var(--header-height) * -1)"
      maxW="2000px"
      mx="auto"
      className="page-border"
    >
      {heroComponent}
      {children}
    </Box>
    <Footer />
  </>
}

LandingHeroLayout.propTypes = {
  children: PropTypes.node,
  heroComponent: PropTypes.node,
};

LandingHeroLayout.defaultProps = {
  children: undefined,
  heroComponent: undefined,
};

export default LandingHeroLayout;
