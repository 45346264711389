import { Box, Grid, GridItem } from "@chakra-ui/react";
import PropTypes from 'prop-types';

function Hero({ children, imageURL, imageFocusPoint, isHomepage }) {
  const backgroundImageUrl = `url('${imageURL}')`;
  return (
    <Grid
      templateColumns="repeat(1, minmax(0, 1fr))"
      templateRows="1fr"
      autoRows="minmax(0, auto)"
    >
      <GridItem
        rowStart="1"
        rowEnd="2"
        colStart="1"
        position="relative"
        minHeight={{ base: isHomepage ? 'calc(var(--header-height) + 601px)' : 'calc(var(--header-height) + 442px)', md: isHomepage ? 'calc(var(--header-height) + 742px)' : 'calc(var(--header-height) + 442px)' }}
      >
        <Box
          backgroundAttachment={{ base: 'scroll', md: 'fixed' }}
          backgroundPosition={imageFocusPoint}
          backgroundSize="cover"
          backgroundRepeat="no-repeat"
          position="absolute"
          zIndex="-2"
          top="0"
          overflow="hidden"
          width="100%"
          height="100%"
          maxHeight={isHomepage ? 'calc(var(--header-height) + 742px)' : 'calc(var(--header-height) + 442px)'}
          // NB: Bug where if this is set as a property, it replaces 'url()' with 'url-gradient()' so have to set it this way
          // See: https://github.com/chakra-ui/chakra-ui/issues/7548
          style={{
            backgroundImage: backgroundImageUrl,
          }}
        />
        {/* Dark overlay */}
        <Box
          position="absolute"
          inset="0"
          backgroundColor="rgba(0,0,0,0.5)"
          width="100%"
          height="100%"
          zIndex="-1"
        />
      </GridItem>

      <GridItem
        rowStart="1"
        rowEnd="2"
        colStart="1"
        borderBottom="1px"
        pt="var(--header-height)"
      >
        {/* Content */}
        <Grid
          templateColumns="11.54% 11.54% 15.38% 11.54% 11.54% 15.38% 11.54% 11.54%"
          height="100%"
        >
          <GridItem
            colSpan={{ base: 8, md: 6 }}
            p={{ base: '15px', md: 'calc(15px + 15 * ((100vw - 600px) / 1080))'}}
            pt="calc(20px + 20 * ((100vw - 600px) / 1080))"
            className="rounded-corners"
          >
            {children}
          </GridItem>
          {/* Empty but for visual grid/rounded corners */}
          <GridItem
            colSpan={{ base: 1, md: 2 }}
            borderLeft={{ md: "1px" }}
          />
        </Grid>
      </GridItem>
    </Grid>
  );
}

Hero.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  imageURL: PropTypes.string,
  imageFocusPoint: PropTypes.string,
  isHomepage: PropTypes.bool,
};

Hero.defaultProps = {
  children: null,
  imageURL: null,
  imageFocusPoint: 'left',
  isHomepage: false,
};

export default Hero;
