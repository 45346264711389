/**
 * Capitalises the first letter of a string.
 *
 * @param {string} str to capitalise
 * @returns
 */
import { COLLECTIONS } from './constants/managed';

const capitaliseSentence = (str) => (str && str[0].toUpperCase() + str.slice(1).toLowerCase()) || '';

/**
 * Capitalises the first letter of every string found in a space separated string.
 *
 * @param {string} str to capitalise
 * @returns
 */
function titleCaseSentence(sentence) {
  const words = sentence.split(' ');

  return words.map((word) => word[0].toUpperCase() + word.substring(1)).join(' ');
}

const shuffleArray = (array) => {
  const newArray = array;

  for (let i = newArray.length - 1; i > 0; i -= 1) {
    const j = Math.floor(Math.random() * (i + 1));
    [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
  }

  return newArray;
};

const capitaliseAllWords = (sentence) => {
  let words = sentence.split(' ');
  words = words.map((word) => word[0].toUpperCase() + word.substr(1));
  return words.join(' ');
};

const taxonChecked = (taxon) => {
  if (taxon) {
    return { [parseInt(taxon, 10)]: true };
  }
  return {};
};

const fetcher = (...args) => fetch(...args)
  .then((res) => res.json())
  .catch(() => ({}));


/**
 * Global modifications to the API responses.
 *
 * Currently:
 *  - Re-orders media so that less interesting images are at the end of a list.
 *  - Prefixes catalogNumber field with "NHMD"
 *
 * @param {array} items
 * @returns
 */
const transformResponse = (items) => {
  if (!items) {
    return [];
  }

  return items.map((result) => {
    const { media } = result;
    if (media.length > 1) {
      media.forEach((image, index) => {
        if (/label/i.test(image.title)) {
          result.media.push(result.media.splice(index, 1)[0]);
        }
      });
      // eslint-disable-next-line no-param-reassign
      result.media = media;
    }

    return result;
  });
};

const allCollectionKeys = COLLECTIONS.map((collection) => collection.key);

export {
  allCollectionKeys,
  capitaliseAllWords,
  capitaliseSentence,
  fetcher,
  shuffleArray,
  taxonChecked,
  titleCaseSentence,
  transformResponse,
};
