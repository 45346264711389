import {
  Box, Flex, Image, Tag, TagLabel,
  Text,
} from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';
import PropTypes from 'prop-types';

import { getThumbnailUrl } from '../../lib/iiif';
import { collectionObjectType } from '../../lib/propTypes';
import NextChakraLink from '../NextChakraLink/NextChakraLink';

// `compact` variation used in MapDrawer
function ObjectCard({
  item,
  titleHTML,
  vernacularName,
  taxonRankVernacularName,
  headingLevel,
  compact,
  includeRoundedCorners,
  includeSeeMore,
  isSearchResult,
}) {
  const { t } = useTranslation('common');
  const thumbnailURL = 'media' in item && item.media.length > 0
    ? getThumbnailUrl(item.media[0].identifier, 410, 410, true, 'square')
    : null;

  const fallbackImg = 'data:image/svg+xml;utf8,%3Csvg xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 512 512"%3E%3Ctext fill="rgba(0,0,0,0.5)" font-family="sans-serif" font-size="30" dy="10.5" font-weight="bold" x="50%25" y="50%25" text-anchor="middle"%3ENo image%3C/text%3E%3C/svg%3E%0A';
  const searchResultsFallbackImg = 'data:image/svg+xml;utf8,%3Csvg xmlns="http://www.w3.org/2000/svg" width="205" height="135" viewBox="0 0 205 135"%3E%3Ctext fill="rgba(0,0,0,0.5)" font-family="sans-serif" font-size="12" font-weight="bold" x="50%25" y="50%25" text-anchor="middle"%3ENo image%3C/text%3E%3C/svg%3E%0A';

  return (
    <Box
      as="li"
      className={includeRoundedCorners ? 'rounded-corners' : ''}
      borderTop="1px"
      borderRight={compact ? '1px' : ''}
      sx={!isSearchResult ? {
        '@media (min-width: 48em)': {
          '&:nth-child(odd)': {
            borderRight: '1px'
          },
        },
      } : ''}
    >
      <NextChakraLink
        href={`/object/${item.catalogNumber}`}
        display="block"
        height="100%"
        _focusVisible={{
          boxShadow: 'outlineCard',
        }}
        _hover={{
          color: 'greyHover',
          '& .arrow-link': {
            _after: {
              transform: 'translateX(2px)',
            }
          }
        }}
      >
        <Flex
          px="sideSpace"
          py={{ base: '5', md: 'sideSpace' }}
          direction={{ base: 'column', sm: isSearchResult && thumbnailURL ? 'row-reverse' : 'row' }}
          gap={{ base: '4', sm: '8' }}
          height="100%"
        >
          {/* IMAGE */}
          {thumbnailURL && (
            <Image
              src={thumbnailURL}
              alt=""
              fallbackSrc={isSearchResult ? searchResultsFallbackImg : fallbackImg}
              objectFit="cover"
              objectPosition="top"
              align="center center"
              width="100%"
              height="100%"
              maxWidth={compact ? '80px' : '205px'}
              // eslint-disable-next-line no-nested-ternary
              maxHeight={compact ? '80px' : (isSearchResult ? '135px' : '200px')}
              bgColor="gray.100"
            />
          )}

          {/* TEXT */}
          <Flex direction="column" justifyContent="space-between" marginRight="auto">
            {/* Title */}
            <Box as={headingLevel}>
              <Text
                as="span"
                display="block"
                textStyle={compact ? 'body' : 'h8'}>
                {titleHTML
                  ? <span dangerouslySetInnerHTML={{ __html: titleHTML }} />
                  : item.scientificName}
              </Text>

              {/* Common name */}
              {!!vernacularName && (
                <Text as="span" textStyle="body" mt="3" display="block">{vernacularName}</Text>
              )}
            </Box>

            {!!taxonRankVernacularName || !!includeSeeMore && (
              <Box mt="4">
                {/* Taxon badge */}
                {!!taxonRankVernacularName && (
                  <Tag size="sm" variant="outline" color="#fff" border="solid 1px #fff" borderRadius="0" p="0.5em">
                    <TagLabel className="taxon-badge" fontWeight="600">{taxonRankVernacularName.toUpperCase()}</TagLabel>
                  </Tag>
                )}

                {/* 'See more' */}
                {!!includeSeeMore && (
                  <Text
                    mt={{ base: '4', sm: '8' }}
                    textStyle="link"
                    className="arrow-link"
                  >{t('see-more')}
                  </Text>
                )}
              </Box>
            )}
          </Flex>
        </Flex>
      </NextChakraLink>
    </Box>
  );
}

ObjectCard.propTypes = {
  item: collectionObjectType.isRequired,
  vernacularName: PropTypes.string,
  taxonRankVernacularName: PropTypes.string,
  titleHTML: PropTypes.string,
  headingLevel: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  compact: PropTypes.bool,
  includeRoundedCorners: PropTypes.bool,
  includeSeeMore: PropTypes.bool,
  isSearchResult: PropTypes.bool,
};

ObjectCard.defaultProps = {
  vernacularName: null,
  taxonRankVernacularName: null,
  titleHTML: null,
  headingLevel: 'h2',
  compact: false,
  includeRoundedCorners: false,
  includeSeeMore: false,
  isSearchResult: false,
};

export default ObjectCard;
