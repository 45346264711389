/* eslint-disable react/jsx-props-no-spreading, react/forbid-prop-types */
import PropTypes from 'prop-types';

import NextChakraLink from "../NextChakraLink/NextChakraLink";

function ArrowLink({ children, href, isExternal, ...rest }) {
  return (
    <NextChakraLink
      href={href}
      display="inline-flex"
      alignItems="center"
      textStyle="link"
      className={isExternal ? 'arrow-link arrow-link--external' : 'arrow-link'}
      _after={{
        // Content added via class
        transition: "color 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94), transform 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94)",
        ...(isExternal ? {
          position: 'relative',
          top: '-0.15em',
        } : {}),
      }}
      _hover={{
        color: 'greyHover',
        _after: {
          transform: isExternal ? 'translate(2px, -2px)' : 'translateX(2px)',
        }
      }}
      {...rest}
    >
      {children}
    </NextChakraLink>
  );
}

ArrowLink.propTypes = {
  children: PropTypes.node.isRequired,
  href: PropTypes.string.isRequired,
  isExternal: PropTypes.bool,
};

ArrowLink.defaultProps = {
  isExternal: false,
};

export default ArrowLink;
