/* eslint-disable react/jsx-props-no-spreading, react/forbid-prop-types */
import { SimpleGrid } from '@chakra-ui/react';
import PropTypes from 'prop-types';

function ListView({
  children, columns, spacing, styleProps,
}) {
  return <SimpleGrid
    as="ul"
    columns={columns}
    minChildWidth={columns > 1 ? 'min(420px, 100%)' : null}
    spacing={spacing}
    listStyleType="none"
    {...styleProps}
  >
    {children}
  </SimpleGrid>
}

ListView.propTypes = {
  children: PropTypes.node,
  columns: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.number,
  ]).isRequired,
  spacing: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.number,
    PropTypes.string,
  ]),
  styleProps: PropTypes.object,
};

ListView.defaultProps = {
  children: undefined,
  spacing: [4, 8, null, null, 12],
  styleProps: {},
};

export default ListView;
