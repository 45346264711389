import PropTypes from 'prop-types';

const collectionType = PropTypes.shape({
  title: PropTypes.string,
  description: PropTypes.string,
});

const collectionObjectType = PropTypes.shape({
  catalogNumber: PropTypes.string,
  collectionCode: PropTypes.string,
  scientificName: PropTypes.string,
  taxonRank: PropTypes.string,
  media: PropTypes.arrayOf(
    PropTypes.shape({
      imageUrl: PropTypes.string,
    }),
  ),
});

const formattedTaxonNamesType = PropTypes.objectOf(
  PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
);

const iiifInfoType = PropTypes.shape(
  {
    '@context': PropTypes.string,
    '@id': PropTypes.string,
    protocol: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    sizes: PropTypes.arrayOf(
      PropTypes.shape({
        width: PropTypes.number,
        height: PropTypes.number,
      }),
    ),
    // eslint-disable-next-line react/forbid-prop-types
    tiles: PropTypes.array,
    // eslint-disable-next-line react/forbid-prop-types
    profile: PropTypes.array,
    crossOriginPolicy: PropTypes.bool,
    ajaxWithCredentials: PropTypes.bool,
    useCanvas: PropTypes.bool,
    version: PropTypes.number,
    // eslint-disable-next-line react/forbid-prop-types
    tileSizePerScaleFactor: PropTypes.object,
    tileWidth: PropTypes.number,
    tileHeight: PropTypes.number,
    maxLevel: PropTypes.number,
  },
);

const taxonNamesType = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.number,
    canonical: PropTypes.string,
    scientific: PropTypes.string,
    vernacular: PropTypes.string,
  }),
);

const phylumTaxonType = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.number,
    canonical: PropTypes.string,
    scientific: PropTypes.string,
    vernacular: PropTypes.string,
    vernacularDa: PropTypes.string,
  }),
);

const identificationPropType = PropTypes.objectOf(PropTypes.string);

const rangeFilterType = PropTypes.shape({
  activeRange: PropTypes.arrayOf(PropTypes.number),
  rangeMin: PropTypes.number,
  rangeMax: PropTypes.number,
});

export {
  collectionObjectType,
  collectionType,
  formattedTaxonNamesType,
  identificationPropType,
  iiifInfoType,
  rangeFilterType,
  taxonNamesType,
  phylumTaxonType,
};
