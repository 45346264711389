import Head from 'next/head';
import PropTypes from 'prop-types';
import React from 'react';

import { HOMEPAGE_HERO_IMAGE } from '../../lib/constants/managed';
import { getThumbnailUrl } from '../../lib/iiif';

const defaultImageSize = 1200;
const { src } = HOMEPAGE_HERO_IMAGE;
const fallbackImage = src ? getThumbnailUrl(src, defaultImageSize) : null;

function SocialMetadata({
  title, pathname, image, description, imageWidth, imageHeight,
}) {
  let baseUrl = 'https://collections.snm.ku.dk';
  if (process.env.NEXT_PUBLIC_VERCEL_ENV !== 'production') {
    baseUrl = `https://${process.env.NEXT_PUBLIC_VERCEL_URL}`;
  }
  const url = `${baseUrl}${pathname}`;

  return (
    <Head>
      <title>{title}</title>
      <meta property="og:title" content={title} key="title" />
      <meta property="og:image" content={image || fallbackImage} key="image" />
      <meta property="og:image:width" content={imageWidth || defaultImageSize} key="imageWidth" />
      <meta property="og:image:height" content={imageHeight || defaultImageSize} key="imageHeight" />
      <meta property="og:url" content={url} key="url" />
      <meta property="og:type" content="website" key="type" />
      <meta property="og:description" content={description} key="description" />
      <meta property="fb:app_id" content="232612175001512" key="app_id" />
      <meta property="twitter:title" content={title} key="twitterTitle" />
      <meta property="twitter:card" content="summary_large_image" key="card" />
      <meta property="twitter:site" content="@NHM_Denmark" key="site" />
      <meta property="twitter:creator" content="@NHM_Denmark" key="creator" />
      <meta property="twitter:image" content={image || fallbackImage} key="twitterImage" />
      <meta property="twitter:description" content={description} key="twitterDescription" />
    </Head>
  );
}

SocialMetadata.propTypes = {
  title: PropTypes.string.isRequired,
  pathname: PropTypes.string.isRequired,
  image: PropTypes.string,
  imageWidth: PropTypes.number,
  imageHeight: PropTypes.number,
  description: PropTypes.string.isRequired,
};

SocialMetadata.defaultProps = {
  image: fallbackImage,
  imageWidth: defaultImageSize,
  imageHeight: defaultImageSize,
};

export default SocialMetadata;
