import { IIIF_URI_ROOT } from './constants/global';
import { fetcher } from './util';

const ALLOWED_FORMATS = 'jpg|jpeg|tif|tiff|png';

/**
 * Helper for fetch resources from Cantaloupe.
 *
 * @param {string} url The url to fetch.
 * @returns
 */
const cantaloupeFetcher = (url) => fetch(url)
  .then((response) => {
    if (response.ok) {
      return response.json();
    }
    return null;
  }).catch(console.log);

/**
 * Get the URL for an image on the IIIF server, given a URL from Specify
 *
 * @param {string} imageUrl
 * @returns
 */
const getImageBaseUrl = (imageUrl) => {
  if (!imageUrl.includes('https://specify-attachments.science.ku.dk/static/')) {
    return null;
  }

  const regex = new RegExp(String.raw`\.(${ALLOWED_FORMATS})$`, 'i');
  const trimmedImageUrl = imageUrl.replace('https://specify-attachments.science.ku.dk/static/', '').replace(regex, '.ptif');

  // The IIIF server is very specific about some parts of the URL being encoded
  return `${IIIF_URI_ROOT}/images${encodeURIComponent(`/ptifs/${trimmedImageUrl}`)}`;
};

/**
 * Get the URL to a IIIF image
 *
 * @param {string} imageUrl - The URL of either an image on the IIIF server or one from Specify
 * @param {number} width - Image width in pixels
 * @param {number} height - Image height in pixels
 * @param {boolean} scale - Whether or not to scale for the best fit such that the resulting width
 *   and height are less than or equal to the requested width and height
 * @param {string} region - The rectangular portion of the image to be returned ('full' or 'square'
 *   are the two most common options)
 * @returns {string} The image URL
 */
const getThumbnailUrl = (imageUrl, width = 400, height = '', scale = false, region = 'full') => {
  const iiifBaseUrl = imageUrl.startsWith(`${IIIF_URI_ROOT}/images`) ? imageUrl : getImageBaseUrl(imageUrl);
  return iiifBaseUrl ? `${iiifBaseUrl}/${region}/${scale ? '!' : ''}${width},${height}/0/default.jpg` : null;
};

async function fetchImageInfo(imageUrl) {
  const iiifBaseUrl = imageUrl.startsWith(`${IIIF_URI_ROOT}/images`) ? imageUrl : getImageBaseUrl(imageUrl);
  const infoJson = await cantaloupeFetcher(`${iiifBaseUrl}/info.json`);
  return (iiifBaseUrl && infoJson) ? infoJson : null;
}

// Used to check if the Manifest generated is valid
async function iiifManifestValidator(iiifManifestUrl) {
  return fetcher(`http://iiif.io/api/presentation/validator/service/validate?format=json&version=2.0&url=${iiifManifestUrl}`);
}

export {
  getThumbnailUrl,
  fetchImageInfo,
  iiifManifestValidator,
};
